"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _getPageContext = require("./getPageContext");

var _styles = require("@material-ui/core/styles");

var _CssBaseline = _interopRequireDefault(require("@material-ui/core/CssBaseline"));

var _JssProvider = _interopRequireDefault(require("react-jss/lib/JssProvider"));

var _DocPreview = _interopRequireDefault(require("./DocPreview"));

var _jss = require("jss");

var _ref2 = _react.default.createElement(_CssBaseline.default, null);

var _ref3 = _react.default.createElement("div", null);

var DocPreviewWithRoot = function DocPreviewWithRoot(_ref) {
  var entry = _ref.entry,
      muiPageContext = _ref.muiPageContext,
      jss = _ref.jss;
  var body = entry.getIn(['data', 'body']);
  var rawDate = entry.getIn(['data', 'date']);
  return _react.default.createElement(_JssProvider.default, {
    jss: jss,
    registry: muiPageContext.sheetsRegistry,
    generateClassName: muiPageContext.generateClassName
  }, _react.default.createElement(_styles.MuiThemeProvider, {
    theme: muiPageContext.theme,
    sheetsManager: muiPageContext.sheetsManager
  }, _ref2, body ? _react.default.createElement(_DocPreview.default, {
    content: body,
    tags: entry.getIn(['data', 'tags']),
    title: entry.getIn(['data', 'title']),
    slug: entry.getIn(['data', 'slug']),
    rawDate: rawDate,
    category: entry.getIn(['data', 'category'])
  }) : _ref3));
};

var DocPreviewTemplate = function DocPreviewTemplate(_ref4) {
  var entry = _ref4.entry;
  var jssInsertionPoint = 'jss-insertion-point';
  var iframe = document.getElementsByTagName('iframe')[0];
  var iframeHeadElem = iframe.contentDocument.head;
  var noscriptElem = document.getElementById('jssInsertionPoint');

  if (!noscriptElem) {
    noscriptElem = document.createElement('noscript');
    noscriptElem.id = jssInsertionPoint;
    iframeHeadElem.appendChild(noscriptElem);
  }

  var linkPrismThemeNode;
  linkPrismThemeNode = document.createElement('link');
  linkPrismThemeNode.setAttribute('type', 'text/css');
  linkPrismThemeNode.setAttribute('rel', 'stylesheet');
  linkPrismThemeNode.setAttribute('href', 'https://unpkg.com/prism-themes@1.0.1/themes/prism-vs.css');
  iframeHeadElem.appendChild(linkPrismThemeNode);
  var jss = (0, _jss.create)((0, _extends2.default)({}, (0, _styles.jssPreset)(), {
    // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
    insertionPoint: noscriptElem
  }));
  var muiPageContext = (0, _getPageContext.createPageContext)(jss);
  return DocPreviewWithRoot({
    entry: entry,
    muiPageContext: muiPageContext,
    jss: jss
  });
};

DocPreviewTemplate.propTypes = process.env.NODE_ENV !== "production" ? {
  entry: _propTypes.default.shape({
    getIn: _propTypes.default.func
  }),
  widgetFor: _propTypes.default.func
} : {};
var _default = DocPreviewTemplate;
exports.default = _default;