"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _styles = require("@material-ui/core/styles");

var styles = function styles(theme) {
  var _root;

  return {
    root: (_root = {
      paddingTop: 80,
      flex: '1 1 100%',
      maxWidth: '100%',
      margin: '0 auto',
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    }, (0, _defineProperty2.default)(_root, theme.breakpoints.up('sm'), {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      maxWidth: 'calc(100% - 52px)'
    }), (0, _defineProperty2.default)(_root, theme.breakpoints.up('lg'), {
      paddingLeft: theme.spacing.unit * 5,
      paddingRight: theme.spacing.unit * 5,
      maxWidth: 'calc(100% - 240px - 200px)'
    }), _root)
  };
};

function AppContent(props) {
  var className = props.className,
      classes = props.classes,
      children = props.children;
  return _react.default.createElement("main", {
    className: (0, _classnames.default)(classes.root, className)
  }, children);
}

AppContent.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes.default.node.isRequired,
  classes: _propTypes.default.object.isRequired,
  className: _propTypes.default.string
} : {};

var _default = (0, _styles.withStyles)(styles)(AppContent);

exports.default = _default;