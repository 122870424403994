"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPageContext = createPageContext;
exports.updatePageContext = updatePageContext;
exports.default = getPageContext;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _jss = require("jss");

var _jssRtl = _interopRequireDefault(require("jss-rtl"));

var _styles = require("@material-ui/core/styles");

var _light = _interopRequireDefault(require("./styles/light"));

var _getTheme = _interopRequireDefault(require("./styles/getTheme"));

/* eslint-disable no-underscore-dangle */
var defaultTheme = (0, _getTheme.default)(_light.default); // Configure JSS

var jss = (0, _jss.create)({
  plugins: [].concat((0, _toConsumableArray2.default)((0, _styles.jssPreset)().plugins), [(0, _jssRtl.default)()])
});

function createPageContext(customJss) {
  return {
    jss: customJss ? customJss : jss,
    theme: defaultTheme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new _jss.SheetsRegistry(),
    generateClassName: (0, _styles.createGenerateClassName)({
      productionPrefix: 'j' // Reduce the bandwidth usage.

    })
  };
}

function updatePageContext(uiTheme) {
  var pageContext = (0, _extends2.default)({}, global.__MUI_PAGE_CONTEXT__, {
    theme: (0, _getTheme.default)(uiTheme)
  });
  global.__MUI_PAGE_CONTEXT__ = pageContext;
  return pageContext;
}

function getPageContext() {
  // Make sure to create a new store for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return createPageContext();
  } // Reuse context on the client-side


  if (!global.__MUI_PAGE_CONTEXT__) {
    global.__MUI_PAGE_CONTEXT__ = createPageContext();
  }

  return global.__MUI_PAGE_CONTEXT__;
}