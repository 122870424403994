"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPrismTheme = setPrismTheme;
exports.default = void 0;

var _prismjs = _interopRequireDefault(require("prismjs"));

require("prismjs/components/prism-css");

require("prismjs/components/prism-diff");

require("prismjs/components/prism-javascript");

require("prismjs/components/prism-jsx");

require("prismjs/components/prism-markup");

require("prismjs/components/prism-typescript");

var _prismVs = _interopRequireDefault(require("prism-themes/themes/prism-vs.css"));

var _prismA11yDark = _interopRequireDefault(require("prism-themes/themes/prism-a11y-dark.css"));

var styleNode;

if (process.browser) {
  styleNode = document.createElement('style');
  styleNode.setAttribute('data-prism', 'true');

  if (document.head) {
    document.head.appendChild(styleNode);
  }
}

function setPrismTheme(theme) {
  if (theme === 'light') {
    styleNode.textContent = _prismVs.default;
  } else {
    styleNode.textContent = _prismA11yDark.default;
  }
}

var _default = _prismjs.default;
exports.default = _default;