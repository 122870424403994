"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _actionTypes = _interopRequireDefault(require("./actionTypes"));

var initialState = {
  theme: {
    paletteType: 'dark'
  }
};
var mapping = (0, _defineProperty2.default)({}, _actionTypes.default.THEME_CHANGE_PALETTE_TYPE, function (state, action) {
  return (0, _extends2.default)({}, state, {
    paletteType: action.payload.paletteType
  });
});

function themeReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  if (mapping[action.type]) {
    newState = mapping[action.type](state, action);
  }

  return newState;
}

var _default = themeReducer;
exports.default = _default;