"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styles = require("@material-ui/core/styles");

var _List = _interopRequireDefault(require("@material-ui/core/List"));

var _Drawer = _interopRequireDefault(require("@material-ui/core/Drawer"));

var _SwipeableDrawer = _interopRequireDefault(require("@material-ui/core/SwipeableDrawer"));

var _Divider = _interopRequireDefault(require("@material-ui/core/Divider"));

var _Hidden = _interopRequireDefault(require("@material-ui/core/Hidden"));

var _gatsbyLink = _interopRequireDefault(require("gatsby-link"));

var _AppDrawerNavItem = _interopRequireDefault(require("./AppDrawerNavItem"));

var styles = function styles(theme) {
  return {
    nav: {
      '& ul': {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    },
    paper: {
      width: 240,
      backgroundColor: theme.palette.background.default
    },
    title: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing.unit / 2,
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    img: {
      width: 170,
      marginTop: 7
    },
    // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
    toolbarIe11: {
      display: 'flex'
    },
    toolbar: (0, _extends2.default)({}, theme.mixins.toolbar, {
      paddingLeft: theme.spacing.unit * 3,
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main
    }),
    anchor: {
      color: theme.palette.text.secondary
    }
  };
}; // eslint-disable-next-line react/prop-types


function renderNavItems(_ref) {
  var pages = _ref.pages,
      params = (0, _objectWithoutProperties2.default)(_ref, ["pages"]);
  return _react.default.createElement(_List.default, null, pages.reduce( // eslint-disable-next-line no-use-before-define
  function (items, page) {
    return reduceChildRoutes((0, _extends2.default)({
      items: items,
      page: page
    }, params));
  }, []));
}

function reduceChildRoutes(_ref2) {
  var props = _ref2.props,
      activePage = _ref2.activePage,
      items = _ref2.items,
      page = _ref2.page,
      depth = _ref2.depth;

  if (page.displayNav === false) {
    return items;
  }

  if (page.isEmptyParent) {
    var title = page.title;
    var openImmediately = activePage.indexOf("".concat(page.path, "/")) === 0;
    items.push(_react.default.createElement(_AppDrawerNavItem.default, {
      depth: depth,
      key: title,
      openImmediately: openImmediately,
      title: title
    }, renderNavItems({
      props: props,
      pages: page.children,
      activePage: activePage,
      depth: depth + 1
    })));
  } else {
    var _title = page.title;
    page = page.children && page.children.length === 1 ? page.children[0] : page;
    items.push(_react.default.createElement(_AppDrawerNavItem.default, {
      depth: depth,
      key: _title,
      title: _title,
      href: page.path,
      onClick: props.onClose
    }));
  }

  return items;
} // iOS is hosted on high-end devices. We can enable the backdrop transition without
// dropping frames. The performance will be good enough.
// So: <SwipeableDrawer disableBackdropTransition={false} />


var iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

var _ref3 = _react.default.createElement(_Divider.default, null);

function AppDrawer(props) {
  var classes = props.classes,
      className = props.className,
      disablePermanent = props.disablePermanent,
      mobileOpen = props.mobileOpen,
      onClose = props.onClose,
      onOpen = props.onOpen,
      currentPage = props.currentPage,
      siteLogo = props.siteLogo;

  var renderLogo = function renderLogo() {
    return _react.default.createElement("img", {
      src: siteLogo,
      alt: "logo",
      width: "170",
      className: classes.img
    });
  };

  var drawer = _react.default.createElement("div", {
    className: classes.nav
  }, _react.default.createElement("div", {
    className: classes.toolbarIe11
  }, _react.default.createElement("div", {
    className: classes.toolbar
  }, _react.default.createElement(_gatsbyLink.default, {
    className: classes.title,
    to: "/"
  }, renderLogo()))), _ref3, renderNavItems({
    props: props,
    pages: currentPage && currentPage.tree ? currentPage.tree : [],
    activePage: currentPage ? currentPage.path : '',
    depth: 0
  }));

  return _react.default.createElement("nav", {
    className: className
  }, _react.default.createElement(_Hidden.default, {
    lgUp: !disablePermanent,
    implementation: "js"
  }, _react.default.createElement(_SwipeableDrawer.default, {
    classes: {
      paper: (0, _classnames.default)(classes.paper, 'algolia-drawer')
    },
    disableBackdropTransition: !iOS,
    variant: "temporary",
    open: mobileOpen,
    onOpen: onOpen,
    onClose: onClose,
    ModalProps: {
      keepMounted: true
    }
  }, drawer)), disablePermanent ? null : _react.default.createElement(_Hidden.default, {
    mdDown: true,
    implementation: "css"
  }, _react.default.createElement(_Drawer.default, {
    classes: {
      paper: classes.paper
    },
    variant: "permanent",
    open: true
  }, drawer)));
}

AppDrawer.propTypes = process.env.NODE_ENV !== "production" ? {
  classes: _propTypes.default.object.isRequired,
  className: _propTypes.default.string,
  disablePermanent: _propTypes.default.bool.isRequired,
  mobileOpen: _propTypes.default.bool.isRequired,
  onClose: _propTypes.default.func.isRequired,
  onOpen: _propTypes.default.func.isRequired,
  currentPage: _propTypes.default.object.isRequired
} : {};

var _default = (0, _styles.withStyles)(styles)(AppDrawer);

exports.default = _default;