"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _styles = require("@material-ui/core/styles");

var getTheme = function getTheme(uiTheme) {
  var themeOptions = {
    typography: {
      useNextVariants: true
    },
    nprogress: {
      color: uiTheme.paletteType === 'light' ? '#000' : '#fff'
    },
    palette: (0, _extends2.default)({}, uiTheme.paletteColors, {
      type: uiTheme.paletteType
    }) // set paper color based on paletteType

  };
  themeOptions.palette.background = uiTheme.paletteType === 'dark' ? {
    paper: '#1c2022',
    default: '#000'
  } : {
    paper: themeOptions.palette.main,
    default: '#fff'
  };
  var theme = (0, _styles.createMuiTheme)(themeOptions); // Expose the theme as a global variable so people can play with it.

  if (process.browser && window) {
    window.theme = theme;
  }

  return theme;
};

var _default = getTheme;
exports.default = _default;