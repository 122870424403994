"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var actionTypes = {
  THEME_CHANGE_PALETTE_TYPE: 'THEME_CHANGE_PALETTE_TYPE'
};
var _default = actionTypes;
exports.default = _default;