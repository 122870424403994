"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DocContent = _interopRequireDefault(require("./DocContent"));

var _format = _interopRequireDefault(require("date-fns/format"));

var DocPreview = function DocPreview(_ref) {
  var content = _ref.content,
      tags = _ref.tags,
      rawDate = _ref.rawDate,
      title = _ref.title,
      category = _ref.category;
  return _react.default.createElement(_DocContent.default, {
    content: content,
    isPreview: true,
    tags: tags,
    title: title,
    formattedDate: (0, _format.default)(rawDate, 'MMMM Do YYYY'),
    category: category
  });
};

DocPreview.propTypes = process.env.NODE_ENV !== "production" ? {
  content: _propTypes.default.node.isRequired,
  rawDate: _propTypes.default.instanceOf(Date),
  title: _propTypes.default.string,
  category: _propTypes.default.string,
  tags: _propTypes.default.array
} : {};
var _default = DocPreview;
exports.default = _default;