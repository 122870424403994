"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initRedux;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _redux = require("redux");

var _themeReducer = _interopRequireDefault(require("./themeReducer"));

/* eslint-disable no-underscore-dangle */
// Get the Redux DevTools extension and fallback to a no-op function
var devtools = function devtools(x) {
  return x;
};

if (process.env.NODE_ENV !== 'production' && process.browser && window.__REDUX_DEVTOOLS_EXTENSION__) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

function create(initialState) {
  var middlewares = [];

  if (process.env.NODE_ENV !== 'production' && process.browser && !window.__REDUX_DEVTOOLS_EXTENSION__ && Object.assign // redux-logger needs this feature
  ) {
      middlewares = (0, _toConsumableArray2.default)(middlewares);
    }

  return (0, _redux.createStore)((0, _redux.combineReducers)({
    theme: _themeReducer.default
  }), initialState, // Hydrate the store with server-side data
  (0, _redux.compose)(_redux.applyMiddleware.apply(void 0, (0, _toConsumableArray2.default)(middlewares)), devtools));
}

function initRedux(initialState) {
  // Make sure to create a new store for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState);
  } // Reuse store on the client-side


  if (!global.__INIT_REDUX_STORE__) {
    global.__INIT_REDUX_STORE__ = create(initialState);
  }

  return global.__INIT_REDUX_STORE__;
}