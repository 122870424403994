"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var PageContext = _react.default.createContext({
  data: {},
  config: {}
});

var _default = PageContext;
exports.default = _default;